<template>
  <div class="flex justify-between text-center mx-auto w-1/2">
    <div class="">
      <div
        class="
          inline-block
          rounded-full
          w-10
          h-10
          text-white
          pt-3
          pb-2
          leading-none
        "
        :class="billingClasses"
      >
        1
      </div>
      <h4 class="text-xs mt-3">Pasul 1</h4>
      <h6 class="text-xs">Detalii de facturare</h6>
    </div>
    <div class="">
      <div
        class="
          inline-block
          rounded-full
          w-10
          h-10
          text-white
          pt-3
          pb-2
          leading-none
        "
        :class="shippingClasses"
      >
        2
      </div>
      <h4 class="text-xs mt-3">Pasul 2</h4>
      <h6 class="text-xs">Detalii de livrare</h6>
    </div>
    <div class="">
      <div
        class="
          inline-block
          rounded-full
          w-10
          h-10
          text-white
          pt-3
          pb-2
          leading-none
        "
        :class="summaryClasses"
      >
        3
      </div>
      <h4 class="text-xs mt-3">Pasul 3</h4>
      <h6 class="text-xs">Sumar comanda</h6>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: Number,
  },

  data() {
    return {
      activeStepClasses: "bg-yellow-400",
      completedStepClasses: "bg-green-500",
      incompletedStepClasses: "bg-green-500",
    };
  },

  computed: {
    billingClasses() {
      if (this.step === 1) return this.activeStepClasses;
      return this.completedStepClasses;
    },
    shippingClasses() {
      if (this.step === 2) return this.activeStepClasses;
      return this.completedStepClasses;
    },
    summaryClasses() {
      if (this.step === 3) return this.activeStepClasses;
      return this.completedStepClasses;
    },
  },
};
</script>

<style></style>
